import React from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Document } from '@contentful/rich-text-types';
import { Button } from '@hurtigruten/design-system-components';
import Link from 'next/link';

import { PageSection, UrgencyOfferMessage } from '@atoms';
import { ButtonModes } from '@constants';
import { useLocale, useMediaQuery, useTranslate } from '@hooks';
import { landing } from '@microcopies';
import { BaselineCard } from '@molecules';
import { Fluid } from '@components';
import { getDateDifferenceInDays, breakpoints } from '@utils';
import { OfferWrapper } from '@content/models/offerWrapper';
import { mapLocaleToOffersUri } from '@src/utils/mappers/uriMappers';

import { Carousel } from '.';

const ExplorerOffers = ({
  title,
  description,
  offers = [],
  isDark
}: {
  title: string;
  description: Document | null;
  offers: OfferWrapper[];
  isDark?: boolean;
}) => {
  const translateLanding = useTranslate(landing, (x) => x.landing);
  const isLaptop = useMediaQuery(breakpoints.laptop);
  const isTablet = useMediaQuery(breakpoints.tablet);
  const locale = useLocale();

  const getOfferUrgencyMessage = (offer: OfferWrapper) => {
    const expiryDate = new Date(offer.expiryDate);
    const daysToExpiry = getDateDifferenceInDays(new Date(), expiryDate, false);

    if (daysToExpiry > 0 && daysToExpiry < 30) {
      return translateLanding((x) => x.offerDaysLeft, {
        days: daysToExpiry.toString()
      });
    }

    const year = expiryDate.getFullYear();
    const month = (1 + expiryDate.getMonth()).toString().padStart(2, '0');
    const day = expiryDate.getDate().toString().padStart(2, '0');

    return translateLanding((x) => x.offerUntil, {
      date:
        locale === 'en-us'
          ? `${month}/${day}/${year}`
          : `${day}/${month}/${year}`
    });
  };

  const getOfferUrgency = (offer: OfferWrapper) =>
    offer.expiryDate ? (
      <UrgencyOfferMessage
        hasBackground
        style="secondary"
        urgencyOfferMessage={getOfferUrgencyMessage(offer)}
        primaryColor="black"
      />
    ) : null;

  const hideCarouselArrows =
    (offers.length <= 4 && isLaptop) || (offers.length <= 3 && isTablet);

  return (
    <PageSection
      title={title}
      leadText={description ? documentToPlainTextString(description) : ''}
      backgroundColor={isDark ? 'hx-caviar' : 'gray'}
    >
      <Fluid className="flex-col items-start">
        <div className="relative flex flex-col w-full mx-auto overflow-hidden max-w-fluid">
          <Carousel
            showSlider={!hideCarouselArrows}
            showArrows={!hideCarouselArrows}
            isNoBoundaryMargin
            isInFluid
            hasDiscretePadding
            sliderType="discrete"
            sliderClassName="px-6 tablet:max-w-fluid mx-auto"
            itemContainerClassName="align-with-fluid-padding pr-6"
            mode={ButtonModes.primary}
            itemsHoverState
            items={offers.map((offer) => (
              <div
                key={offer.title}
                className="max-w-[240px] tablet:max-w-[278px] relative"
              >
                <BaselineCard
                  imageTag={getOfferUrgency(offer)}
                  id={offer.sysId}
                  contentType={offer.__typename}
                  hasFullHeight
                  isInsideCarousel
                  href={`/${locale}/${mapLocaleToOffersUri(locale)}/${
                    offer.slug
                  }`}
                  size="small"
                  title={offer.teaserTitle}
                  imageUrl={offer?.image?.url}
                  imageAlt={offer?.image?.title}
                  description={
                    <p className="whitespace-pre-line">
                      {offer.teaserDescription ?? ''}
                    </p>
                  }
                />
              </div>
            ))}
          />
          <div className="self-center mt-5 tablet:mt-12">
            <Link href={`/${mapLocaleToOffersUri(locale)}`} passHref legacyBehavior>
              <Button
                appearance={isDark ? 'secondary' : 'primary'}
                fillMobileWidth={false}
                text={translateLanding((x) => x.seeAllOffers)}
              />
            </Link>
          </div>
        </div>
      </Fluid>
    </PageSection>
  );
};

export default ExplorerOffers;

import clsx from 'clsx';
import NextLink from 'next/link';
import { ForwardedRef, forwardRef, useRef } from 'react';
import { Icon } from '@hurtigruten/design-system-components';

import { ContentfulImage, Tag } from '@atoms';
import { Fluid } from '@components';
import { useLocale, useMediaQuery, useTranslate } from '@hooks';
import { common } from '@microcopies';
import { Tags, StoryCardFooter } from '@molecules';
import { VidiconLine } from '@icons/Media';
import { defaultLocale } from '@hooks/useLocale';
import { generateResponsiveSizesString, breakpoints } from '@src/utils';
import ContentfulXRAY from '@components/contentful/ContentfulXray';

export const getImageSizeForCard = (
  cardSize: string,
  orientation: string,
  isInsideCarousel: boolean,
  isMobile: boolean
) => {
  let width = 0;
  let height = 0;

  if (isInsideCarousel) {
    // size is static for every breakpoint
    if (cardSize === 'normal') {
      return {
        width: 377,
        height: 283
      };
    }
    return {
      width: 278,
      height: 173
    };
  }

  if (cardSize === 'normal' && orientation === 'column') {
    // these numbers represent the widest baselinecard in a grid
    width = isMobile ? 719 : 549;
    height = isMobile ? 581 : 411;
  } else if (cardSize === 'normal' && orientation === 'row') {
    // row turns to column on mobile
    width = isMobile ? 719 : 590;
    height = isMobile ? 581 : 447;
  } else {
    // currently we have no small cards outside of carousels
    width = 278;
    height = 173;
  }

  return { width, height };
};

type TBaselineCardProps = {
  imageUrl?: string;
  imageAlt?: string;
  id?: string;
  contentType?: string;
  size?: 'small' | 'normal' | 'large';
  title: string;
  description?: string | React.ReactNode;
  imageTag?: React.ReactNode;
  buttonHref?: string;
  href?: string;
  onClick?: (event: MouseEvent) => void;
  onButtonClick?: () => void;
  readingTime?: number;
  videoUrl?: string;
  tags?: string[];
  ariaLabel?: string;
  rounded?: 'full' | 'top' | 'bottom';
  buttonText?: string;
  orientation?: 'column' | 'row';
  hasFixedWidth?: boolean;
  hasFullHeight?: boolean;
  hasFullWidth?: boolean;
  subTitle?: string;
  hasImage?: boolean;
  hasRoundedImage?: boolean;
  isTransparent?: boolean;
  isWhiteText?: boolean;
  isInsideCarousel?: boolean;
  legend?: string;
};

const InnerBaselineCard = forwardRef(
  (
    {
      imageUrl,
      size = 'normal',
      title,
      description,
      buttonText,
      onButtonClick,
      readingTime,
      videoUrl,
      tags,
      imageTag,
      ariaLabel,
      orientation = 'column',
      hasFullHeight = false,
      hasFixedWidth = false,
      hasFullWidth = false,
      imageAlt,
      subTitle,
      href,
      onClick,
      hasImage = true,
      isTransparent = false,
      isWhiteText = false,
      isInsideCarousel = false,
      legend
    }: TBaselineCardProps,
    ref
  ): JSX.Element => {
    const translate = useTranslate(common, (x) => x.common);
    const isDragging = useRef(false);
    const draggingTimeout = useRef<number | undefined>();
    const isMobile = useMediaQuery(breakpoints.tablet, true);

    const imageSize = getImageSizeForCard(
      size,
      orientation,
      isInsideCarousel,
      isMobile
    );

    const handleCardClick = (event: MouseEvent) => {
      if (isDragging.current) return;
      if (onClick) {
        if (event.ctrlKey || event.metaKey) {
          window.open(href, '_blank');
        } else {
          onClick?.(event);
        }
      } else {
        onButtonClick?.();
      }
    };

    const handlePointerMove = () => {
      isDragging.current = true;
      clearTimeout(draggingTimeout.current);
      draggingTimeout.current = window.setTimeout(() => {
        isDragging.current = false;
      }, 100);
    };

    return (
      <button
        aria-label={ariaLabel}
        ref={ref as ForwardedRef<HTMLButtonElement>}
        onPointerMove={handlePointerMove}
        onClick={handleCardClick}
        tabIndex={0}
        {...({
          href
        } as any)}
        className={clsx(
          'flex relative text-left bg-white overflow-hidden drop-shadow-sm laptop:drop-shadow-card-shadow group laptop:baseLineCard-hover',
          {
            'flex-col': orientation === 'column',
            'flex-col tablet:flex-row': orientation === 'row',
            'w-[272px] tablet:w-[400px]': hasFixedWidth,
            'w-full': hasFullWidth,
            'h-full': hasFullHeight,
            '!bg-transparent': isTransparent,
            '!text-white': isWhiteText,
            '!cursor-default': !onClick && !onButtonClick
          }
        )}
      >
        <article
          className={clsx('flex w-full h-full', {
            'flex-col': orientation === 'column',
            'flex-col tablet:flex-row': orientation === 'row'
          })}
          data-testid="baselineCard"
        >
          {Boolean(hasImage) && (
            <div className="flex-1">
              <div data-testid="baselineCard-image">
                {Boolean(imageTag) && (
                  <div className="absolute z-10 top-4 left-4">{imageTag}</div>
                )}
                <div className="relative">
                  {videoUrl && (
                    <div className="absolute z-10 top-4 left-4">
                      <Tag
                        size="small"
                        value={translate((x) => x.video)}
                        hasIcon
                        style={'black'}
                        icon={VidiconLine}
                        iconLeft
                      />
                    </div>
                  )}
                  <ContentfulImage
                    // for more pixel ctrl I wanted to use intrinsic as default
                    // but its hard when components are responsive
                    // within a grid
                    layout={isInsideCarousel ? 'intrinsic' : 'responsive'}
                    src={imageUrl}
                    objectFit="cover"
                    alt={imageAlt}
                    {...imageSize}
                    sizes={
                      !isInsideCarousel
                        ? generateResponsiveSizesString({
                            vwMobile: '100vw',
                            vwTablet: '50vw',
                            vwLaptop: '33vw'
                          })
                        : ''
                    }
                  />
                </div>
              </div>
            </div>
          )}

          <Fluid
            isNoMargin
            className={clsx(
              'relative flex flex-col items-start w-full pb-6 whitespace-normal',
              {
                'pb-0': buttonText || videoUrl || readingTime,
                'grow-9999': orientation === 'column'
              }
            )}
          >
            <div
              className={clsx('grow-9999', {
                'p-6 pb-3 tablet:flex tablet:items-center tablet:px-12 tablet:py-0':
                  orientation === 'row',
                'p-6 pb-3 min-h-[230px]': orientation === 'column',
                '!px-0': isTransparent
              })}
            >
              <div
                className={clsx({
                  'tablet:gap-4 tablet:grid': orientation === 'row'
                })}
              >
                {subTitle && <p className="pb-2 overline-text">{subTitle}</p>}
                <h3
                  className={clsx('flex-spacer h5-text', {
                    'pb-2': orientation === 'column',
                    'group-hover:underline': !buttonText && onClick
                  })}
                  data-testid="baselineCard-title"
                >
                  {title}
                </h3>
                {legend && (
                  <p className="mb-2 text-hx-light-gray-3">{legend}</p>
                )}
                {description && (
                  <div
                    className="line-clamp-6"
                    data-testid="baselineCard-description"
                  >
                    {typeof description === 'string' ? (
                      <p>{description}</p>
                    ) : (
                      description
                    )}
                  </div>
                )}

                {tags && (
                  <div
                    className={clsx({
                      'my-4': orientation === 'column',
                      'my-4 tablet:my-2': orientation === 'row'
                    })}
                    data-testid="baselineCard-tags"
                  >
                    <Tags
                      size="small"
                      tags={tags.map((tag) => ({
                        isClickable: false,
                        value: tag
                      }))}
                    />
                  </div>
                )}
              </div>
            </div>

            {(readingTime || videoUrl || buttonText) && (
              <StoryCardFooter
                orientation={orientation}
                hasBorder={
                  [tags, readingTime, videoUrl, buttonText].filter(Boolean)
                    .length > 1
                }
              >
                {readingTime && !videoUrl && (
                  <span
                    className="py-4 caption text-light-black"
                    data-testid="baselineCard-readingTime"
                  >
                    {translate((x) => x.readingTime, {
                      readingTime: `${readingTime}`
                    })}
                  </span>
                )}
                {videoUrl && (
                  <span
                    className="py-4 caption text-light-black"
                    data-testid="baselineCard-video"
                  >
                    {translate((x) => x.video)}
                  </span>
                )}
                {buttonText && (
                  <div className="flex justify-end w-full">
                    <div className="mr-2 ui-text hover:underline">
                      {buttonText}
                    </div>
                    <Icon graphic="arrow-right" />
                  </div>
                )}
              </StoryCardFooter>
            )}
          </Fluid>
        </article>
      </button>
    );
  }
);

const BaselineCard = (props: TBaselineCardProps) => {
  const locale = useLocale();

  if (props.buttonHref || props.href) {
    return (
      <ContentfulXRAY
        title={props.title}
        contentType={props.contentType}
        entryID={props.id}
      >
        <NextLink
          legacyBehavior
          href={(props.buttonHref || props.href) as string}
          locale={locale ?? defaultLocale}
          prefetch={false}
          passHref
          className={clsx(
            'flex font-normal hover:no-underline focus:no-underline no-anchor-style',
            {
              'w-full': props.hasFullWidth,
              'h-full': props.hasFullHeight
            }
          )}
        >
          <InnerBaselineCard {...props} />
        </NextLink>
      </ContentfulXRAY>
    );
  }

  return (
    <ContentfulXRAY
      title={props.title}
      contentType={props.contentType}
      entryID={props.id}
    >
      <InnerBaselineCard {...props} key={props.title} />
    </ContentfulXRAY>
  );
};

InnerBaselineCard.displayName = 'InnerBaselineCard';
export default BaselineCard;
